<template>
	<div class="role-management">
		<h1>Edit Role</h1>
		<!-- GET ROLE FORM -->
		<AddRoleForm></AddRoleForm>
	</div>
	<!-- end container -->
	<div class="client-button__wrapper" v-if="can(getAuthData, 'delete_global_role') && getAuthData.username !== getUserUsername">
		<button class="button button--delete-role" v-on:click="deleteRole()">Delete Role</button>
	</div>
</template>
<script>
import AddRoleForm from "../../components/forms/AddRoleForm.vue"
import {mapGetters} from "vuex"

export default {
	name: "roleManagement",
	data() {
		return {}
	},
	components: {
		AddRoleForm,
	},
	methods: {
		async deleteRole(){	
			let confirmed = confirm(`Are you sure you want to delete role?`);
			if(confirmed){
				this.$store.dispatch('roles/deleteRole', this.$store.getters["roles/getRoleData"].id);
			}
		}
	},
	computed: {
		...mapGetters("auth", ["getAuthData"]),
		...mapGetters("user", ["getUserUsername"]),
		...mapGetters("client", ["getRoleData"]),
	},
}
</script>
